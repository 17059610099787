.IsRotateArrow {
  transform: rotate(90deg);
}

.menu-item.active {
  background-color: black; /* Cambia el color de fondo según tu preferencia */
}

.hover-dropdown-menu:hover {
  background-color: #f2f2f2;
}
