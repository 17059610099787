.async-export-xlsx-container {
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  margin: 0;
}
.async-export-xlsx-container > .async-export-xlsx-button {
  width: 120px;
  height: 40px;
  padding: 5px;
}

.async-export-xlsx-container > p {
  margin: 0;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
