.admin-panel-container {
  display: flex;
  flex-direction: row;
}

.button-data-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}
.admin-panel-container .text {
  padding: 0 5px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.admin-panel-container .text > .text-desc-value,
.text-desc {
  text-align: right;
  margin: 0;
}
.admin-panel-container .text > .text-desc {
  font-size: 16px;
  text-align: right;
  margin: 0;
}
.admin-panel-container .text > .text-desc-value {
  font-size: 12px;
}
.admin-panel-container .text > .text-desc-value > .text-desc-value-user {
  font-weight: bold;
}
